import React, { FC } from 'react';

import ItemListingCounter from './ItemListingCounter';
import ItemListingDefault from './ItemListingDefault';
import ItemListingLoadMore from './ItemListingLoadMore';
import ItemListingRails from './ItemListingRails';
import { ItemListingCarouselProps, ItemListingProps } from './models';

const ItemListing: FC<ItemListingProps | ItemListingCarouselProps> = (itemListing) => {
  // eslint-disable-next-line react/destructuring-assignment
  const type = 'type' in itemListing && itemListing.type;

  if (type === 'numbered') {
    return <ItemListingCounter {...(itemListing as ItemListingProps)} />;
  }
  if (type === 'load-more') {
    return <ItemListingLoadMore {...(itemListing as ItemListingProps)} />;
  }
  if (type === 'default') {
    return <ItemListingDefault {...(itemListing as ItemListingProps)} />;
  }
  if ('carousel' in itemListing) {
    return <ItemListingRails {...(itemListing as ItemListingCarouselProps)} />;
  }

  return null;
};

export default ItemListing;
