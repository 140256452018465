import React, { FC } from 'react';
import { CtaBlock } from '@design-system/cta-block';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';
import { Spacer } from '@design-system/spacer';

import { ItemListingProps } from '../models';

export const ItemListingLoadMore: FC<Omit<ItemListingProps, 'type'>> = ({
  pageBlock,
  fluidGrid,
  items,
  loadMoreButtonText,
}) => (
  <PageBlock {...pageBlock} className="item-listing-load-more-block">
    <div>
      <FluidGrid {...fluidGrid}>{items}</FluidGrid>
      <Spacer size={7} />
      <CtaBlock
        align="center"
        // TODO: Handle load more button if we decide how to do it
        onClick={() => console.log('Load more?', loadMoreButtonText)}
        data={[
          {
            elementType: 'button',
            variant: 'primary',
            size: 'large',
            children: loadMoreButtonText,
          },
        ]}
      />
    </div>
  </PageBlock>
);

export default ItemListingLoadMore;
