import React, { FC } from 'react';
import { Carousel } from '@design-system/carousel';
import { PageBlock } from '@design-system/page-block';

import { ItemListingCarouselProps } from '../models';

export const ItemListingRails: FC<ItemListingCarouselProps> = ({ pageBlock, carousel }) => (
  <PageBlock {...pageBlock} className="item-listing-rails-block">
    <Carousel
      {...carousel}
      ariaLivePlaceholder={(activeIndex, totalCount) => `Show ${activeIndex} of ${totalCount}`}
    />
  </PageBlock>
);

export default ItemListingRails;
