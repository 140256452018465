import React, { FC } from 'react';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';

import { ItemListingProps } from '../models';

export const ItemListingCounter: FC<Omit<ItemListingProps, 'type'>> = ({
  pageBlock,
  fluidGrid,
  items,
}) => (
  <PageBlock {...pageBlock} className="item-listing-counter-block">
    <FluidGrid {...fluidGrid}>
      {items.map((item) => (
        <li key={item.key}>{item}</li>
      ))}
    </FluidGrid>
  </PageBlock>
);

export default ItemListingCounter;
