import React, { FC } from 'react';
import { FluidGrid } from '@design-system/fluid-grid';
import { PageBlock } from '@design-system/page-block';

import { ItemListingProps } from '../models';

export const ItemListingDefault: FC<Omit<ItemListingProps, 'type'>> = ({
  pageBlock,
  fluidGrid,
  items,
}) => (
  <PageBlock {...pageBlock} className="item-listing-default-block">
    <FluidGrid {...fluidGrid}>{items}</FluidGrid>
  </PageBlock>
);

export default ItemListingDefault;
